/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from 'react-pro-sidebar';
import {FaTachometerAlt, FaHeart} from 'react-icons/fa';
import * as ActionScreen from '../../store/actions/setStateScreen';
import * as ActionRestaurante from '../../store/actions/setRestaurante';
import TimelineIcon from '@material-ui/icons/Timeline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import StorefrontIcon from '@material-ui/icons/Storefront';
import RoomIcon from '@material-ui/icons/Room';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MapIcon from '@material-ui/icons/Map';
import WarningIcon from '@material-ui/icons/Warning';
import HelpIcon from '@material-ui/icons/Help';
import ScheduleIcon from '@material-ui/icons/Schedule';
import logo from '../../assets/logo.png';
import logomini from '../../assets/logomini.png';
import TodayIcon from '@material-ui/icons/Today';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import {useSelector} from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// eslint-disable-next-line no-unused-vars
import {getAuth} from 'firebase/auth';

const GreenCheckbox = withStyles({
  root: {
    color: '#C4C4C4',
    '&$checked': {
      color: '#FF6600',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const Aside = ({
  image,
  setCollapsed,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  toggleInfo,
  setToggleInfo,
  toggleEnd,
  setToggleEnd,
  togglePay,
  setTogglePay,
  handleBackDrop,
  handleNotiStack,
  isSendingEntregaIreit,
  setSendingEntregaIreit,
  toggleAratdm,
  setToggleAratdm,
  toggleEspera,
  setToggleEspera,
  toggleHorario,
  setToggleHorario,
}) => {
  const auth = getAuth();
  const intl = useIntl();
  const dispatch = useDispatch();
  const npds = useSelector(state => state.setcounters.pedidos);
  function changeScreen(route) {
    handleToggleSidebar(false);
    dispatch(ActionScreen.setChangeScreen(route));
    scrollToTop();
  }

  function handleToggledButton() {
    if (collapsed === false) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }

  const nome = useSelector(state => state.setrestaurante.nome);
  const img = useSelector(state => state.setrestaurante.img);
  const descricao = useSelector(state => state.setrestaurante.descricao);
  const contato = useSelector(state => state.setrestaurante.contato);
  const cat = useSelector(state => state.setrestaurante.cat);
  const pedidominimo = useSelector(state => state.setrestaurante.pedidominimo);

  const endereco = useSelector(state => state.setrestaurante.endereco);
  const numero = useSelector(state => state.setrestaurante.numero);
  const bairro = useSelector(state => state.setrestaurante.bairro);
  const cidade = useSelector(state => state.setrestaurante.cidade);
  const estado = useSelector(state => state.setrestaurante.estado);
  const location = useSelector(state => state.setrestaurante.endereco);
  const bapp = useSelector(state => state.setrestaurante.bapp);
  const bvir = useSelector(state => state.setrestaurante.bvir);
  const bvref = useSelector(state => state.setrestaurante.bvref);
  const bpre = useSelector(state => state.setrestaurante.bpre);

  const state = useSelector(state => state.setrestaurante);
  const inAlta = useSelector(state => state.setrestaurante.inAlta);
  const ireitentrega = useSelector(state => state.setrestaurante.ireitentrega);
  const propria = useSelector(state => state.setrestaurante.entrega);
  const retirada = useSelector(state => state.setrestaurante.retirada);
  const docid = useSelector(state => state.setrestaurante.id);
  const [checkboxAlta, setCheckBoxAlta] = useState(false);
  const [checkboxEntrega, setCheckBoxEntrega] = useState(false);
  const [checkboxRetirada, setCheckBoxRetirada] = useState(false);
  const [checkboxPropria, setCheckBoxPropria] = useState(false);
  const [isSendingRetirada, setSendingRetirada] = useState(false);
  const [isSendingEntregaPropria, setSendingEntregaPropria] = useState(false);
  const [isSendingEmAlta, setSendingEmAlta] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleChangeAlta = event => {
    setSendingEmAlta(true);
    let newstate = event.target.checked;
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/store/update/inalta/' +
          docid +
          '?toggle=' +
          event.target.checked,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setTimeout(() => {
                  setCheckBoxAlta(newstate);
                  dispatch(ActionRestaurante.setInAlta(newstate));
                  setSendingEmAlta(false);
                  if (newstate) {
                    handleNotiStack('Restaurante em alta demanda', 'success');
                  } else {
                    handleNotiStack(
                      'Restaurante com demanda normal',
                      'success',
                    );
                  }
                }, 1000);
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            setSendingEmAlta(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setSendingEmAlta(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setSendingEmAlta(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setSendingEmAlta(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setSendingEmAlta(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setSendingEmAlta(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  };

  const handleChangeRetirada = event => {
    setSendingRetirada(true);
    let newstate = event.target.checked;
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/store/update/retirada/' +
          docid +
          '?toggle=' +
          event.target.checked,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setTimeout(() => {
                  setCheckBoxRetirada(newstate);
                  dispatch(ActionRestaurante.setRetirada(newstate));
                  setSendingRetirada(false);
                  if (newstate) {
                    handleNotiStack(
                      'Pedidos com retirada habilitado',
                      'success',
                    );
                  } else {
                    handleNotiStack(
                      'Pedidos com retirada desabilitado',
                      'warning',
                    );
                  }
                }, 1000);
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            setSendingRetirada(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setSendingRetirada(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setSendingRetirada(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setSendingRetirada(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setSendingRetirada(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setSendingRetirada(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  };

  const handleChangeEntPropria = event => {
    setSendingEntregaPropria(true);
    let newstate = event.target.checked;
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/store/update/entrega/' +
          docid +
          '?toggle=' +
          event.target.checked,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setTimeout(() => {
                  setCheckBoxPropria(newstate);
                  dispatch(ActionRestaurante.setPropria(newstate));
                  setSendingEntregaPropria(false);
                  if (newstate) {
                    handleNotiStack('Entrega própria habilitada', 'success');
                  } else {
                    handleNotiStack('Entrega própria desabilitada', 'warning');
                  }
                }, 1000);
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            setSendingEntregaPropria(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setSendingEntregaPropria(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setSendingEntregaPropria(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setSendingEntregaPropria(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setSendingEntregaPropria(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setSendingEntregaPropria(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  };

  const handleChangeEntrega = event => {
    setSendingEntregaIreit(true);
    let newstate = event.target.checked;
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/store/update/entregaii/' +
          docid +
          '?toggle=' +
          event.target.checked,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setTimeout(() => {
                  setCheckBoxEntrega(newstate);
                  dispatch(ActionRestaurante.setIreitEntrega(newstate));
                  setSendingEntregaIreit(false);
                  if (newstate) {
                    handleNotiStack('Entrega Irê it habilidata', 'success');
                  } else {
                    handleNotiStack('Entrega Irê it desabilitada', 'warning');
                  }
                }, 1000);
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            setSendingEntregaIreit(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setSendingEntregaIreit(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setSendingEntregaIreit(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setSendingEntregaIreit(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setSendingEntregaIreit(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setSendingEntregaIreit(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setCheckBoxAlta(inAlta);
      setCheckBoxEntrega(ireitentrega);
      setCheckBoxPropria(propria);
      setCheckBoxRetirada(retirada);
    }, 1000);
  }, [state]);

  useEffect(() => {
    if (
      nome === '' ||
      img === '' ||
      descricao === '' ||
      contato === '' ||
      cat === '' ||
      pedidominimo === 0
    ) {
      setToggleInfo(false);
    } else {
      setToggleInfo(true);
    }

    if (
      endereco === '' ||
      bairro === '' ||
      cidade === '' ||
      estado === '' ||
      location.latitude === 0.0 ||
      location.longitude === 0.0
    ) {
      setToggleEnd(false);
    } else {
      setToggleEnd(true);
    }

    if (!bapp && !bpre && !bvir && !bvref) {
      setTogglePay(false);
    } else {
      setTogglePay(true);
    }

    try {
      if (
        state.aratdm.coordinates[0].length > 3 &&
        state.aratdmii.coordinates[0].length > 3
      ) {
        setToggleAratdm(true);
      } else {
        setToggleAratdm(false);
      }
    } catch (err) {
      console.debug(err);
    }

    let bessm = false;
    state.essm.map(item => {
      if (item.bopen) {
        bessm = true;
      }
    });

    let besfds = false;
    state.esfds.map(item => {
      if (item.bopen) {
        besfds = true;
      }
    });

    if (besfds || bessm) {
      setToggleEspera(true);
    } else {
      setToggleEspera(false);
    }

    let bdtsm = false;
    state.dtsm.map(item => {
      if (item.bopen) {
        bdtsm = true;
      }
    });

    let bdtfds = false;
    state.dtfds.map(item => {
      if (item.bopen) {
        bdtfds = true;
      }
    });

    if (bdtfds || bdtsm) {
      setToggleHorario(true);
    } else {
      setToggleHorario(false);
    }
  }, [bapp, bpre, bvir, bvref, state]);

  return (
    <ProSidebar
      style={{height: '100%'}}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            {!collapsed ? (
              <img style={{width: 'auto', height: 60}} src={logo} alt="Logo" />
            ) : (
              <img
                style={{width: 'auto', height: 60}}
                src={logomini}
                alt="Logo"
              />
            )}
          </div>
        </div>
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <IconButton
            style={{
              backgroundColor: '#06448e',
              paddingTop: 5,
              paddingBottom: 5,
            }}
            aria-label="close"
            onClick={() => handleToggledButton()}
          >
            {collapsed ? (
              <ChevronRightIcon style={{color: '#FFFFFF'}} />
            ) : (
              <ChevronLeftIcon style={{color: '#ffffff'}} />
            )}
          </IconButton>
        </div>
        <Menu style={{marginTop: 0}} iconShape="circle">
          <MenuItem
            icon={
              !isSendingEmAlta ? (
                <GreenCheckbox
                  onChange={handleChangeAlta}
                  checked={checkboxAlta}
                />
              ) : (
                <div
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 5,
                  }}
                >
                  <CircularProgress style={{color: '#FFFFFF'}} size={15} />
                </div>
              )
            }
          >
            {' '}
            {intl.formatMessage({id: 'Em alta demanda'})}
          </MenuItem>
          <MenuItem
            icon={
              !isSendingRetirada ? (
                <GreenCheckbox
                  onChange={handleChangeRetirada}
                  checked={checkboxRetirada}
                />
              ) : (
                <div
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 5,
                  }}
                >
                  <CircularProgress style={{color: '#FFFFFF'}} size={15} />
                </div>
              )
            }
          >
            {' '}
            {intl.formatMessage({id: 'Retirada'})}
          </MenuItem>
          <MenuItem
            icon={
              !isSendingEntregaPropria ? (
                <GreenCheckbox
                  onChange={handleChangeEntPropria}
                  checked={checkboxPropria}
                />
              ) : (
                <div
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 5,
                  }}
                >
                  <CircularProgress style={{color: '#FFFFFF'}} size={15} />
                </div>
              )
            }
          >
            {' '}
            {intl.formatMessage({id: 'Entrega própria'})}
          </MenuItem>
          <MenuItem
            onClick={() => changeScreen('AtendimentoIreit')}
            style={{alignItems: 'center'}}
            icon={
              !isSendingEntregaIreit ? (
                <GreenCheckbox checked={checkboxEntrega} />
              ) : (
                <div
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 5,
                  }}
                >
                  <CircularProgress style={{color: '#FFFFFF'}} size={15} />
                </div>
              )
            }
          >
            {' '}
            {intl.formatMessage({id: 'Entrega Irê it'})}
          </MenuItem>
        </Menu>
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem
            onClick={() => changeScreen('Inicio')}
            icon={<FaTachometerAlt />}
            routerLink={<Link to="/inicio" />}
          >
            {intl.formatMessage({id: 'Inicio'})}
          </MenuItem>
          <MenuItem
            suffix={
              npds === 0 ? null : (
                <span
                  style={{
                    backgroundColor: '#ff6600',
                    color: '#FFFFFF',
                    borderRadius: 30,
                    paddingLeft: 7,
                    paddingRight: 7,
                    fontSize: 12,
                  }}
                >
                  {npds}
                </span>
              )
            }
            onClick={() => changeScreen('Gestor')}
            icon={<ReceiptIcon />}
          >
            {' '}
            {intl.formatMessage({id: 'Pedidos'})}
          </MenuItem>
          <MenuItem
            onClick={() => changeScreen('Catalogo')}
            icon={<MenuBookIcon />}
          >
            {' '}
            {intl.formatMessage({id: 'Catálogo'})}
          </MenuItem>
          <MenuItem
            onClick={() => changeScreen('Promocoes')}
            icon={<LoyaltyIcon />}
          >
            {' '}
            {intl.formatMessage({id: 'Promoções'})}
          </MenuItem>
          <MenuItem
            onClick={() => changeScreen('Historico')}
            icon={<ScheduleIcon />}
          >
            {' '}
            {intl.formatMessage({id: 'Histórico'})}
          </MenuItem>
          <SubMenu
            title={intl.formatMessage({id: 'Ocorrências'})}
            icon={<WarningIcon />}
          >
            <MenuItem onClick={() => changeScreen('OcorrenciaGet')}>
              {intl.formatMessage({id: 'Acompanhar ocorrências'})}
            </MenuItem>
          </SubMenu>
        </Menu>

        <Menu iconShape="circle">
          <MenuItem
            suffix={
              toggleInfo ? null : (
                <span
                  style={{
                    backgroundColor: '#ff6600',
                    color: '#FFFFFF',
                    borderRadius: 30,
                    paddingLeft: 7,
                    paddingRight: 7,
                    fontSize: 14,
                  }}
                >
                  !
                </span>
              )
            }
            onClick={() => changeScreen('Informacoes')}
            icon={<StorefrontIcon />}
          >
            {intl.formatMessage({id: 'Perfil'})}
          </MenuItem>
          <MenuItem
            suffix={
              toggleEnd ? null : (
                <span
                  style={{
                    backgroundColor: '#ff6600',
                    color: '#FFFFFF',
                    borderRadius: 30,
                    paddingLeft: 7,
                    paddingRight: 7,
                    fontSize: 14,
                  }}
                >
                  !
                </span>
              )
            }
            onClick={() => changeScreen('Location')}
            icon={<RoomIcon />}
          >
            {' '}
            {intl.formatMessage({id: 'Endereço'})}
          </MenuItem>
          <MenuItem
            onClick={() => changeScreen('Espera')}
            icon={<ScheduleIcon />}
            suffix={
              toggleEspera ? null : (
                <span
                  style={{
                    backgroundColor: '#ff6600',
                    color: '#FFFFFF',
                    borderRadius: 30,
                    paddingLeft: 7,
                    paddingRight: 7,
                    fontSize: 14,
                  }}
                >
                  !
                </span>
              )
            }
          >
            {' '}
            {intl.formatMessage({id: 'Tempo de espera'})}
          </MenuItem>
          <MenuItem
            suffix={
              togglePay ? null : (
                <span
                  style={{
                    backgroundColor: '#ff6600',
                    color: '#FFFFFF',
                    borderRadius: 30,
                    paddingLeft: 7,
                    paddingRight: 7,
                    fontSize: 14,
                  }}
                >
                  !
                </span>
              )
            }
            onClick={() => changeScreen('Frm')}
            icon={<AccountBalanceWalletIcon />}
          >
            {intl.formatMessage({id: 'Formas de pagamentos'})}
          </MenuItem>

          <SubMenu
            //prefix={<span className="badge gray">3</span>}
            title={intl.formatMessage({id: 'Área de atendimeto'})}
            icon={<MapIcon />}
            suffix={
              toggleAratdm ? null : (
                <span
                  style={{
                    backgroundColor: '#ff6600',
                    color: '#FFFFFF',
                    borderRadius: 30,
                    paddingLeft: 7,
                    paddingRight: 7,
                    fontSize: 14,
                  }}
                >
                  !
                </span>
              )
            }
          >
            <MenuItem onClick={() => changeScreen('Atendimento')}>
              {intl.formatMessage({id: 'Entrega própria'})}
            </MenuItem>
            <MenuItem onClick={() => changeScreen('AtendimentoIreit')}>
              {intl.formatMessage({id: 'Entrega Irê it'})}
            </MenuItem>
          </SubMenu>

          <MenuItem
            onClick={() => changeScreen('Horarios')}
            icon={<TodayIcon />}
            suffix={
              toggleHorario ? null : (
                <span
                  style={{
                    backgroundColor: '#ff6600',
                    color: '#FFFFFF',
                    borderRadius: 30,
                    paddingLeft: 7,
                    paddingRight: 7,
                    fontSize: 14,
                  }}
                >
                  !
                </span>
              )
            }
          >
            {intl.formatMessage({id: 'Horários'})}
          </MenuItem>

          <MenuItem
            onClick={() => changeScreen('Avaliacoes')}
            icon={<FaHeart />}
          >
            {' '}
            {intl.formatMessage({id: 'Avaliações'})}
          </MenuItem>
          <SubMenu
            title={intl.formatMessage({id: 'Análise de dados'})}
            icon={<TimelineIcon />}
          >
            <MenuItem onClick={() => changeScreen('Vendas')}>
              {intl.formatMessage({id: 'Vendas'})}
            </MenuItem>
            <MenuItem onClick={() => changeScreen('Clientes')}>
              {intl.formatMessage({id: 'Clientes'})}
            </MenuItem>
          </SubMenu>
          <SubMenu
            //prefix={<span className="badge gray">3</span>}
            title={intl.formatMessage({id: 'Financeiro'})}
            icon={<MonetizationOnIcon />}
          >
            <MenuItem onClick={() => changeScreen('Faturas')}>
              {intl.formatMessage({id: 'Faturas'})}
            </MenuItem>
            <MenuItem onClick={() => changeScreen('DadosBancarios')}>
              {intl.formatMessage({id: 'Dados Bancários'})}
            </MenuItem>
          </SubMenu>
          <MenuItem onClick={() => changeScreen('Ajuda')} icon={<HelpIcon />}>
            {' '}
            {intl.formatMessage({id: 'Ajuda'})}
          </MenuItem>
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
};

export default Aside;
